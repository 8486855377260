@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Black.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Black.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Black-Oblique.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Black-Oblique.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Bold.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Bold-Oblique.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Bold-Oblique.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Medium.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Medium-Oblique.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Medium-Oblique.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Regular.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Regular-Oblique.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Regular-Oblique.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Light.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Light-Oblique.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Light-Oblique.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Thin.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('fonts/GT-Walsheim-Thin-Oblique.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Thin-Oblique.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
